import type {CamelCaseKeys} from "camelcase-keys";

import type {components} from "./deal-room-api-schema";

type Address = CamelCaseKeys<components["schemas"]["Address"], true>;

const getFullAddress = (address: Address) =>
  [
    address.street,
    address.apartment,
    address.city,
    `${address.state} ${address.zip}`
  ]
    .filter(Boolean)
    .join(", ");

export {getFullAddress};
