import {NonCachedImage} from "../non-cached-image";

import noPropertyPhotoImage from "./no-property-photo.svg";

type Props = {
  alt: string;
  url?: string;
};

const getUrlProps = (url?: string) =>
  url
    ? {
        blurDataURL: noPropertyPhotoImage,
        placeholder: "blur" as const,
        src: url
      }
    : {
        src: noPropertyPhotoImage
      };

const PropertyPhoto = ({alt, url}: Props) => (
  <NonCachedImage
    {...{alt, ...getUrlProps(url)}}
    layout="fill"
    loading="lazy"
    objectFit="cover"
  />
);

export default PropertyPhoto;
