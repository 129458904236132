import Image from "next/image";
import type {ImageProps} from "next/image";

type Props = Omit<ImageProps, "loader" | "unoptimized">;

const NonCachedImage = (props: Props) => (
  <Image {...props} loader={({src}) => src} unoptimized />
);

export {NonCachedImage};
